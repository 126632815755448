import React from 'react'

import './Privacy.css'
import hoppiaLogo from '../assets/images/hoppia_logo_black.png'

const Privacy = props => {
  return <div id='privacy'>
    <div className="logo">
      <img src={hoppiaLogo} alt='Hoppia logo' />
    </div>
    <h2>Aviso de privacidad</h2>
    <div className='div info_container'>
      <p>
        Alucina Studio S.C., con domicilio ubicado en: Valladolid #25 Colonia Roma Norte, Delegación Cuauhtémoc, Ciudad de México, en cumplimiento a la Ley Federal de Protección de Datos Personales en Posesión de Particulares (“la Ley”), y su reglamento, hacen de su conocimiento que: “Alucina Studio”, protege y salvaguarda sus datos personales para evitar el daño, pérdida, destrucción, robo, extravío, alteración, así como el tratamiento no autorizado.
      </p>
      <p>
        ¿Qué información recopilamos?
      </p>
      <p>
        Los datos personales que usted proporcione a “Alucina Studio” a través de cualquier medio físico o electrónico podrían incluir: (i) datos personales, (ii) datos laborales e (iii) información financiera o contable.
      </p>
      <p>
        El tratamiento de datos personales de terceros que usted nos proporcione, se hace de conformidad con los términos y condiciones contenidos en el Aviso de Privacidad.
        Usted manifiesta su consentimiento para el tratamiento de sus datos personales de acuerdo a los términos y condiciones de este Aviso de Privacidad.
        </p>
      <p>
        ¿Para qué utilizamos su información?
      </p>
      <p>
        Los datos personales que recopilamos son destinados para: (i) fines de identificación y de verificación, (ii) información de nuestros clientes o aliados, (iii) definir perfiles de usuario y (iv) envío de promociones propias.
      </p>
      <p>
        En la recolección y tratamiento de datos personales, cumplimos con todos los principios estipulados en la Ley y su Reglamento: licitud, calidad, consentimiento, información, finalidad, lealtad, proporcionalidad y responsabilidad.
      </p>
      <p>
        ¿Con quién compartimos su información?
      </p>
      <p>
        “Alucina Studio” puede transferir sus datos personales a terceros nacionales o extranjeros pertenecientes a nuestros grupo económico, a nuestras empresas subsidiarias y relacionadas; por requerimiento de una autoridad; y para el cumplimiento de obligaciones derivadas de una relación jurídica entre el titular de los datos personales y “Alucina Studio”.
      </p>
      <p>
        Los datos personales que usted proporciona a “Alucina Studio”, pueden compilarse y fijarse en una base de datos propiedad exclusiva de “Alucina Studio” para ser utilizada en los términos antes referidos.
      </p>
      <p>
        ¿Qué medidas de seguridad y control utilizamos para la protección de sus datos personales?
      </p>
      <p>
        “Alucina Studio”, implementa medidas de seguridad administrativas, técnicas y físicas para proteger sus datos personales, mismas que exigimos sean cumplidas por nuestros proveedores de servicios o clientes, inclusive tratándose de servicios que prestan las empresas subsidiarias o relacionadas.
      </p>
      <p>
        Toda la información y datos personales que usted nos proporcione mediante una vista, en la utilización de nuestros servicios en línea, por escrito y/o vía telefónica, constituirá una base de datos de “Alucina Studio”. La información se almacena con fines de protección, para evitar pérdida, uso indebido o alteración.
      </p>
      <p>
        “Alucina Studio” puede utilizar en su sitio web “cookies” o “direcciones IP”, para confirmar su identificación al tener acceso a nuestros servicios en línea, con el sólo propósito de otorgarle un servicio personalizado debido a que nos permite determinar sus preferencias mediante el registro de su uso en nuestro portal, y de esta manera identificar sus hábitos y preferencias para ofrecerle nuestros productos y servicios.
      </p>
      <p>
        Mediante el uso de “cookies”, no recabamos datos personales de los usuarios en términos de la Ley y su Reglamento.
      </p>
      <p>
        La seguridad y la confidencialidad de los datos que los usuarios proporcionen al comprar una franquicia con nosotros o adquirir algunos de nuestros productos o servicios estarán protegidas por un servidor seguro, de tal forma que los datos recabados si se llegasen a transmitir, se transmitirán encriptados para asegurar su resguardo.
      </p>
      <p>
        ¿Cuál es el área responsable del manejo y administración de los datos personales?
      </p>
      <p>
        El área responsable del manejo y la administración de los datos personales puede contactarse, directamente en nuestras oficinas ubicadas en: Valladolid #25 Colonia Roma Norte, Delegación Cuauhtemoc, Ciudad de México ¿Cómo pude usted limitar el uso o divulgación de sus datos personales?
      </p>
      <p>
        Puede dirigirse a “Alucina Studio” para el caso de que desee limitar el uso o divulgación de sus datos personales para dejar de recibir información de ofertas, promociones y publicidad de productos y servicios adicionales.
        En el caso de empleo de cookies, el botón “ayuda” que se encuentra en la barra de herramientas de la mayoría de los navegadores, le dirá cómo bloquear o permitir las cookies.
      </p>
      <p>
        ¿Cómo puede usted ejercer sus derechos ARCO y revocar el consentimiento otorgado?
      </p>
      <p>
        ejercer los Derechos ARCO (acceso, cancelación, rectificación y oposición al tratamiento de datos personales), o bien, revocar el consentimiento que usted haya otorgado a “Alucina Studio” para el tratamiento de sus datos personales, enviando su solicitud, directamente a nuestras oficinas; o bien, solicitándolo por escrito, enviando al domicilio Valladolid #25 Colonia Roma Norte Delegación Cuauhtémoc, Ciudad de México contener por lo menos su nombre, domicilio completo, documentos que acrediten su identidad, señalando claramente el vínculo de relación con la Compañía y especificando en forma clara y precisa los datos personales de los que solicita su acceso, rectificación, actualización o cancelación, los elementos o documentos en donde pueden obrar los datos personales e indicando las razones por las cuales desea acceder a sus datos personales, o las razones por las que considera que sus datos deben ser actualizados, rectificados o cancelados. o bien, solicitándolo por escrito, enviando al domicilio Valladolid #25 Colonia Roma Norte Delegación Cuauhtémoc, Ciudad de México por lo menos su nombre, domicilio completo, documentos que acrediten su identidad, señalando claramente el vínculo de relación con la Compañía y especificando en forma clara y precisa los datos personales de los que solicita su acceso, rectificación, actualización o cancelación, los elementos o documentos en donde pueden obrar los datos personales e indicando las razones por las cuales desea acceder a sus datos personales, o las razones por las que considera que sus datos deben ser actualizados, rectificados o cancelados.
      </p>
      <p>
        A partir de la recepción, el área de Privacidad de Datos, tendrá un plazo de 20 (veinte) días hábiles para responder a su solicitud, sin embargo, dicho plazo podrá ser ampliado por un periodo de tiempo igual, lo que le será notificado, por el medio que usted nos haya indicado para tales efectos, señalando las razones de dicha ampliación, de conformidad con el artículo 32 de la Ley.
      </p>
      <p>
        Modificaciones al Aviso de Privacidad
      </p>
      <p>
        Nos reservamos el derecho de cambiar este Aviso de Privacidad en cualquier momento. En caso de que exista algún cambio en este Aviso de Privacidad, usted tendrá acceso al mismo a través de nuestro Portal https://alucinastudio.com/
      </p>
      <p>
        Consentimiento de tratamiento de datos personales
      </p>
      <p>
        El titular de datos personales manifiesta haber leído y estar de acuerdo con los términos y condiciones del Aviso de Privacidad puesto a su disposición, conociendo la finalidad de la recolección y tratamiento de sus datos personales, así como el procedimiento para el ejercicio de sus Derechos ARCO.
      </p>
      <p>
        Al proporcionar información vía internet, telefónica o personalmente, usted otorga su consentimiento al presente Aviso de Privacidad.
      </p>
      <p>
        Ante quién puede presentar sus quejas y denuncias por el tratamiento indebido de sus datos personales
      </p>
      <p>
        Si usted considera que su derecho de protección de datos personales ha sido transgredido por alguna conducta de nuestros empleados o de nuestras actuaciones o respuestas, presume que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, podrá interponer la queja o denuncia correspondiente ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI), para mayor información, le sugerimos visitar su página oficial de internet www.inai.org.mx.
      </p>
      <p>
        Fecha de última actualización de este Aviso de Privacidad: 10/10/2018
      </p>
    </div>
  </div>

}

export default Privacy