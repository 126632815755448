import React, { useEffect, useRef, useState } from 'react'
import { isActive } from '../../utils/activeElements'

import './Video.css'

let scrollSize = 0

const Video = props => {
  const videoRef = useRef(null)
    , { scrollPos, limit } = props
    , [elementDimensions, setElementDimensions] = useState(null)
    , onResize = () => {
      let dimensions = videoRef.current.getBoundingClientRect()
        , sizes = { top: dimensions.top, bottom: dimensions.bottom, height: dimensions.height }

      if (scrollSize > 0) {
        sizes.top = scrollSize + sizes.top
        sizes.bottom = scrollSize + sizes.bottom
      }
      setElementDimensions(sizes)
    }

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  let classes = ''
  if (elementDimensions) {
    scrollSize = scrollPos
    classes = isActive(scrollPos, elementDimensions, limit, 50, 180, 'video')
  }

  return <div id='video' className={classes} ref={videoRef}>
    <div className='container'>
      <div className='video-container'>
        <iframe title='hoppia' src={`https://www.youtube.com/embed/hqCu6cqTuDQ?rel=0`} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
      </div>
    </div>
  </div>
}

export default Video