import React, { useEffect, useRef, useState } from 'react'
import { isActive } from '../../utils/activeElements'
import './Description.css'

let scrollSize = 0

const Description = props => {
  const descriptionRef = useRef(null)
    , { scrollPos, limit } = props
    , [elementDimensions, setElementDimensions] = useState(null)
    , onResize = () => {
      let dimensions = descriptionRef.current.getBoundingClientRect()
        , sizes = { top: dimensions.top, bottom: dimensions.bottom, height: dimensions.height }

      if (scrollSize > 0) {
        sizes.top = scrollSize + sizes.top
        sizes.bottom = scrollSize + sizes.bottom
      }
      setElementDimensions(sizes)
    }

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  let classes = ''
  if (elementDimensions) {
    scrollSize = scrollPos
    classes = isActive(scrollPos, elementDimensions, limit, 40, 200, 'description')
  }
  return <div id='description' ref={descriptionRef}>
    <div className='content'>
      <div className={`text-container ${classes}`} >
        <div className='line-text'>
          <p><b>Conciertos</b></p>
          <p><span>, festivales,</span></p>
        </div>
        <div className='line-text'>
          <p><span>exposiciones,</span></p>
          <p>&ensp;<b>premieres</b><span>,</span></p>
        </div>
        <div className='line-text'>
          <p><b>conferencias</b></p>
          <p><span>, ferias </span></p>
          <p>&ensp;<b>y más...</b></p>
        </div>
      </div>
    </div>

    <div className='wave-container'>
      <div className='wave' dangerouslySetInnerHTML={{
        __html: `
          <svg viewBox="0 0 500 150" preserveAspectRatio="none">
            <path d="M-20.87,64.63 C31.59,253.13 279.91,-28.13 537.81,4.45 L573.36,226.47 L0.00,150.00 Z" style="stroke: none; fill: #F5F5F5;"></path>
          </svg>
          `
      }}>
      </div>
    </div>
  </div>
}

export default Description