import React, { useState, useEffect } from 'react'
import Button from '../components/Button'
import hoppiaLogo from '../assets/images/hoppia_logo_black.png'


import { fbDatabase } from '../services/fb'

import axios from 'axios'
import qs from 'qs'

import './index.css'

const PwdRecovery = props => {
  let [classes, setClases] = useState('active')
    , [uid, setUid] = useState('')
    , [pwd, setPwd] = useState('')
    , [confirmPwd, setConfirmPwd] = useState('')
    , [isSending, setIsSending] = useState(false)
    , [error, setError] = useState('')
    , [isLoading, setIsLoading] = useState(true)
    , [pwdUpdate, setPwdUpdate] = useState(false)

  const onSubmit = () => {
    setError('')
    if (!pwd.length || !confirmPwd.length)
      return setError('Los campos son obligatorios')
    if (pwd !== confirmPwd)
      return setError('Los campos no coinciden')
    if (pwd.trim().length < 6)
      return setError('La contraseña debe tener al menos 6 caracteres')

    setIsSending(true)
    axios({
      method: 'POST',
      url: 'https://us-central1-hoppiaemail.cloudfunctions.net/hoppiaPassword',
      data: qs.stringify({
        uid: uid,
        password: pwd,
        accessToken: '243be4feca2f29b0df293f076b7a7499'
      })
    })
      .then(res => {
        if (!res.data.uid) {
          setError('Error al cambiar la contraseña')
        } else {
          setPwdUpdate(true)
          setError('Se modifico la contraseña correctamente')
        }
        setIsSending(false)
      })
      .catch(error => {
        setError(error)
        setIsSending(false)
      })

    console.log(uid, pwd)

    //https://us-central1-hoppiaemail.cloudfunctions.net/hoppiaPassword

  }

  useEffect(() => {
    let { id } = props.match.params

    fbDatabase.ref(`/pwdRequest/${id}`)
      .once('value', snapshot => {
        let tokenSnapshot = snapshot.val()

        if (tokenSnapshot) {
          let currentTime = new Date().getTime()
            , expiresAt = new Date(tokenSnapshot.expiresAt).getTime()

          if (expiresAt - currentTime > 0) {
            setUid(tokenSnapshot.uid)
          } else {
            props.history.push('/')
          }
          setIsLoading(false)
        } else {
          props.history.push('/')
          setIsLoading(false)
        }

      })

    let timeout = null
    timeout = setTimeout(() => {
      setClases('active')
    }, 200)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  if (isLoading) return null

  if (pwdUpdate) return <div id='downloads' className={`pwd-recovery ${classes}`}>
    <div className='container'>
      <div>
        <div className="logo">
          <a href='https://hoppia.world' rel='noopener noreferrer'><img src={hoppiaLogo} alt='Hoppia logo' /></a>
        </div>
      </div>
      <p className='error'>{error}</p>
      <Button isActive={true} onClick={() => props.history.push('/')}>
        <span>Ir a Home</span>
      </Button>
    </div>
  </div>

  return <div id='downloads' className={`pwd-recovery ${classes}`}>
    <div className='container'>
      <div>
        <div className="logo">
          <a href='https://hoppia.world' rel='noopener noreferrer'><img src={hoppiaLogo} alt='Hoppia logo' /></a>
        </div>
        <h3 className='title' >Genera tu nueva contraseña</h3>
        <input
          value={pwd}
          onChange={e => setPwd(e.target.value)}
          type='password'
          placeholder='Contraseña' />
        <input
          value={confirmPwd}
          onChange={e => setConfirmPwd(e.target.value)}
          type='password'
          placeholder='Confirmar contraseña' />
      </div>
      {
        isSending
          ? <span id='spin-loader' />
          : <Button isActive={true} onClick={onSubmit}>
            <span>Enviar</span>
          </Button>
      }
      <p className='error'>{error}</p>
    </div>
  </div>
}

export default PwdRecovery