import React, { useEffect, useRef, useState } from 'react'
import { isActive } from '../../utils/activeElements'

import liveIcon from '../../assets/images/iconstream.png'
import interactionIcon from '../../assets/images/iconinteraction.png'
import gameIcon from '../../assets/images/icongame.png'
import stageIcon from '../../assets/images/iconescenario.png'
import storeIcon from '../../assets/images/iconstore.png'
import avatarIcon from '../../assets/images/iconavatar.png'
import './Features.css'

let scrollSize = 0

const Features = props => {
  const descriptionRef = useRef(null)
    , { scrollPos, limit } = props
    , [elementDimensions, setElementDimensions] = useState(null)
    , onResize = () => {
      let dimensions = descriptionRef.current.getBoundingClientRect()
        , sizes = { top: dimensions.top, bottom: dimensions.bottom, height: dimensions.height }

      if (scrollSize > 0) {
        sizes.top = scrollSize + sizes.top
        sizes.bottom = scrollSize + sizes.bottom
      }
      setElementDimensions(sizes)
    }

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])
  let classes = ''
  if (elementDimensions) {
    scrollSize = scrollPos
    classes = isActive(scrollPos, elementDimensions, limit, 50, 220, 'features')
  }

  return <div id='features' className={classes} ref={descriptionRef}>
    <div className='feature'>
      <img src={liveIcon} alt='streaming' />
      <div className='text-container'>
        <p>Presentaciones</p>
        <p>en <b>streaming</b> o 3D</p>
      </div>
    </div>
    <div className='feature'>
      <img src={interactionIcon} alt='interacción' />
      <div className='text-container'>
        <p><b>Interacción</b></p>
        <p>entre asistentes</p>
      </div>
    </div>
    <div className='feature'>
      <img src={gameIcon} alt='juegos' />
      <div className='text-container'>
        <p>Juegos y</p>
        <p><b>activaciones</b></p>
      </div>
    </div>
    <div className='feature'>
      <img src={stageIcon} alt='escenarios' />
      <div className='text-container'>
        <p>Multiples <b>escenarios</b></p>
      </div>
    </div>
    <div className='feature'>
      <img src={storeIcon} alt='tienda' />
      <div className='text-container'>
        <p>Tienda <b>virtual</b></p>
      </div>
    </div>
    <div className='feature'>
      <img src={avatarIcon} alt='avatares' />
      <div className='text-container'>
        <p>Con avatares</p>
        <p><b>personalizados</b></p>
      </div>
    </div>
  </div>
}

export default Features