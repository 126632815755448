import React, { useState, useEffect } from 'react'
import Scrollbar from 'react-smooth-scrollbar'
import { Route, Switch } from 'react-router-dom'

import Hero from './components/Hero'
import Description from './components/Description'
import Events from './components/Events'
import Features from './components/Features'
import Video from './components/Video'
import Platforms from './components/Platforms'
import EventForm from './components/EventForm'
import Alucina from './components/Alucina'
import Contact from './components/Contact'
import PwdRecovery from './PwdRecovery'

import Downloads from './Downloads'
import Privacy from './Privacy'

import './App.css'

const Home = props => {
  const [scrollPos, setScrollPos] = useState({ x: 0, y: 0 })
    , [limit, setLimit] = useState({ x: 0, y: 10000 })

  useEffect(() => {
    if (props.location.hash) {
      let hash = props.location.hash.replace('#', '')
      let element = document.getElementById(hash)
      if (element) element.scrollIntoView({ behavior: "smooth" })
    }

    const scrollHandler = e => {
      setLimit({ x: 0, y: window.innerHeight })
      setScrollPos({ x: 0, y: window.pageYOffset })
    }
    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <div id='hoppia'>
    <Scrollbar>
      <Hero />
      <Description scrollPos={scrollPos.y} limit={limit.y} />
      <Events scrollPos={scrollPos.y} limit={limit.y} />
      <Features scrollPos={scrollPos.y} limit={limit.y} />
      <Video scrollPos={scrollPos.y} limit={limit.y} />
      <Platforms scrollPos={scrollPos.y} limit={limit.y} />
      <EventForm scrollPos={scrollPos.y} limit={limit.y} />
      <Alucina scrollPos={scrollPos.y} limit={limit.y} />
      <Contact scrollPos={scrollPos.y} limit={limit.y} />
    </Scrollbar>
  </div>
}


const App = () => {
  return <Switch>
    <Route exact path='/' component={Home} />
    <Route exact path='/descarga' component={Downloads} />
    <Route exact path='/aviso-de-privacidad' component={Privacy} />
    <Route exact path='/contrasena/:id' component={PwdRecovery} />
    <Route path='/' component={Home} />
  </Switch>
}

export default App
