import React, { useState, useEffect } from 'react'
import androidIcon from '../assets/images/iconandroid.png'
import iosIcon from '../assets/images/iconios.png'
import appleIcon from '../assets/images/iconapple.png'
import windowsIcon from '../assets/images/iconwindow.png'
import './Downloads.css'
import hoppiaLogo from '../assets/images/logo_white.png'
import Contact from '../components/Contact'
import { fbDatabase } from '../services/fb'

const Downloads = props => {
  let [classes, setClases] = useState('')
    , [links, setLinks] = useState({
      AndroidURL: '',
      AndroidVersion: "0.0.1",
      IOSURL: "",
      IOSVersion: "0.0.0",
      MacOsURL: "",
      MacOsVersion: "1.8.7",
      WindowsURL: "",
      WindowsVersion: "1.8.7"
    })

  useEffect(() => {
    const linksRef = fbDatabase.ref('APPSETTINGS')
    let timeout = null
    linksRef.on('value', snapshot => {
      let linksSnapshot = snapshot.val()
      setLinks(linksSnapshot)
      timeout = setTimeout(() => {
        setClases('active')
      }, 200)
    })



    return () => {
      clearTimeout(timeout)
    }
  }, [])

  let onScroll = element => {
    props.history.push(`/#${element}`)
  }


  return <div id='downloads' className={classes}>
    <div id='hero'>
      <video autoPlay muted loop >
        <source src='https://hoppia.s3.us-west-2.amazonaws.com/website/website_downloads_loop_720.mp4' type='video/mp4' />
      </video>
      <div className={`content ${classes}`}>
        <div className='header'>
          <span onClick={() => onScroll('eventos')}>Eventos</span>
          <span onClick={() => onScroll('descargas')}>Descargar</span>
          <span onClick={() => onScroll('organizar-evento')}>Organizar evento</span>
          <span onClick={() => onScroll('contacto')}>Contacto</span>
        </div>
        <div className='title-container'>
          <div className='banner'>
            <svg viewBox="0 0 500 150" preserveAspectRatio="none" >
              <path d="M-55.86,-22.20 C77.88,49.83 455.98,218.58 500.56,54.77 L500.00,150.00 L0.00,150.00 Z" style={{ stroke: 'none', fill: 'white' }}></path>
            </svg>
          </div>
          <div className="logo">
            <a href='https://hoppia.world' rel='noopener noreferrer'><img src={hoppiaLogo} alt='Hoppia logo' /></a>
          </div>
          <span className='title'>Vívelo ahora en</span>
        </div>
        <div className={`platforms ${classes}`}>

          <div className='platform-container'>
            <div className='platform-item'>
              <img src={androidIcon} alt='android' />
              <div className='button'>
                <a href={links.AndroidURL} target='_blank' rel='noopener noreferrer'>Descargar</a>
              </div>
            </div>

            <div className='platform-item'>
              <img src={iosIcon} alt='ios' />
              <div className='button'>
                <a href={links.IOSURL} target='_blank' rel='noopener noreferrer'>Descargar</a>
              </div>
            </div>

            <div className='platform-item'>
              <img src={appleIcon} alt='apple store' />
              <div className='button'>
                <a href={links.MacOsURL} target='_blank' rel='noopener noreferrer'>Descargar</a>
              </div>
            </div>

            <div className='platform-item'>
              <img src={windowsIcon} alt='windows' />
              <div className='button'>
                <a href={links.WindowsURL} target='_blank' rel='noopener noreferrer'>Descargar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Contact />
  </div>
}

export default Downloads
// <span>Próximamente</span>