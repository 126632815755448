import React from 'react'
import gradient from '../assets/images/gradient-button.svg'

const Button = ({ isActive = false, onClick = () => null, children = null }) => {
  return <div
    className={`button ${!isActive ? 'button-inactive' : ''}`}
    onClick={onClick}
    style={{ background: isActive ? `url("${gradient}")` : '#D6D6D5' }}>
    <div className='button-container'>
      {children}
    </div>
  </div>
}

export default Button