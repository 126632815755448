import React, { useRef, useState } from 'react'
import fbIcon from '../../assets/images/icon_fb.png'
import igIcon from '../../assets/images/icon_ig.png'
import twIcon from '../../assets/images/icon_tw.png'
import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha'

import './Contact.css'

const isDev = process.env.NODE_ENV === 'development'
  , requestUrl = isDev
    ? 'https://hoppia.herokuapp.com/email-test'
    : 'https://hoppia.herokuapp.com/email'


const Contact = props => {

  const [email, setEmail] = useState('')
    , [isEmailRegistered, setIsEmailRegistered] = useState(false)
    , [isEmailValid, setIsEmailValid] = useState(false)
    , [isSending, setIsSending] = useState(false)
    , [error, setError] = useState('')
    , captchaRef = useRef(1)

  const onSendEmail = () => {
    const form = new FormData()
    form.append('email', email)
    setIsSending(true)

    axios({ method: 'POST', url: requestUrl, data: { email } })
      .then(res => {
        setTimeout(() => {
          setIsSending(false)
          if (res.data === 'Sended') setIsEmailRegistered(true)
          else setError('Ocurrió un error, por favor intentalo de nuevo ')
        }, 1000)
      })
      .catch(err => {
        setError('Ocurrió un error, por favor intentalo de nuevo ')
        setIsSending(false)
      })
  }

  const executeCaptcha = () => {
    if (isEmailValid)
      isDev
        ? onSendEmail()
        : captchaRef.current.execute()
    else
      setError('Ingresa un correo electrónico valido <ejemplo@hoppia.world>')
  }

  const validateEmail = e => {
    //eslint-disable-next-line
    let emailPattern = /^(([^<>()\[\]\\.,:\s@"]+(\.[^<>()\[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    setEmail(e.target.value)
    setIsEmailValid(emailPattern.test(e.target.value))
  }

  const onKeyDown = e => { if (e.key === 'Enter') executeCaptcha() }


  return <div id='contacto'>
    <div>
      <h3 className="bigFont">Entérate antes <br /> que nadie</h3>
    </div>
    {
      !isEmailRegistered
        ? <div className='form-container'>
          <p>Regístrate ahora para recibir nuestro newsletter y enterarte <br className="hidden-xs" /> de nuestros próximos eventos y noticias</p>
          <div className={`form ${isSending ? 'active' : ''}`}>
            {
              !isSending
                ? <input
                  className={`${isSending ? 'active' : ''}`}
                  value={email}
                  onChange={validateEmail}
                  onKeyDown={onKeyDown}
                  type='email'
                  placeholder='Correo electrónico' />
                : null
            }
            <div onClick={executeCaptcha} className='btn'>
              {
                isSending
                  ? <span id='spin-loader' />
                  : <span className='send-btn'>Enviar</span>
              }
            </div>
          </div>
          {
            email.length
              ? <p className='error'>{error}</p>
              : null
          }
        </div>
        : <h3 style={{ marginTop: '2vh' }}>Gracias, pronto recibirás más información.</h3>
    }

    {
      !isDev
        ? <ReCAPTCHA
          ref={captchaRef}
          sitekey='6LeB3QAVAAAAAH1yl2ezeFk0NYH9k8XbhFQ5M7UB'
          size='invisible'
          onChange={onSendEmail}
        />
        : null
    }

    <div className='contant-info'>
      <div className='contact-text'>
        <p className='title'>Contáctanos</p>

        <p>hello@hoppia.world</p>
        <p>Valladolid 25, Roma Norte, CDMX</p>
        <p>México</p>
        <p>+52 55 5739 6061</p>
      </div>

      <div className='social-networks'>
        <a href='https://www.facebook.com/hoppia.world/' target='_blank' rel='noopener noreferrer'>
          <img src={fbIcon} alt='fb-logo' />
        </a>
        <a href='https://www.instagram.com/hoppia.world/' target='_blank' rel='noopener noreferrer'>
          <img src={igIcon} alt='ig-logo' />
        </a>
        <a href='https://twitter.com/HoppiaWorld' target='_blank' rel='noopener noreferrer'>
          <img src={twIcon} alt='tw-logo' />
        </a>
      </div>
    </div>

  </div>
}

export default Contact