export const isActive = (scrollPos, elementPos, limit, activeAt, disableAt, tag) => {
  let height = document.body.offsetHeight
    , isActive = ''
    , bottom = scrollPos + height
    , percentage = (bottom - elementPos.top) / elementPos.height * 100


  if (activeAt <= percentage) isActive = 'active in'
  //if (activeAt <= percentage && disableAt <= percentage) isActive = 'out'
  return isActive
}