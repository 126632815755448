import React, { useEffect, useRef, useState } from 'react'
import { isActive } from '../../utils/activeElements'
import { fbDatabase } from '../../services/fb'
import androidIcon from '../../assets/images/iconandroid.png'
import iosIcon from '../../assets/images/iconios.png'
import appleIcon from '../../assets/images/iconapple.png'
import windowsIcon from '../../assets/images/iconwindow.png'

import './Platforms.css'

let scrollSize = 0

const Platforms = props => {
  const platformsRef = useRef(null)
    , { scrollPos, limit } = props
    , [elementDimensions, setElementDimensions] = useState(null)
    , onResize = () => {
      let dimensions = platformsRef.current.getBoundingClientRect()
        , sizes = { top: dimensions.top, bottom: dimensions.bottom, height: dimensions.height }

      if (scrollSize > 0) {
        sizes.top = scrollSize + sizes.top
        sizes.bottom = scrollSize + sizes.bottom
      }
      setElementDimensions(sizes)
    }
    , [links, setLinks] = useState({
      AndroidURL: '',
      AndroidVersion: "0.0.1",
      IOSURL: "",
      IOSVersion: "0.0.0",
      MacOsURL: "",
      MacOsVersion: "1.8.7",
      WindowsURL: "",
      WindowsVersion: "1.8.7"
    })

  useEffect(() => {
    onResize()
    const linksRef = fbDatabase.ref('APPSETTINGS')
    linksRef.on('value', snapshot => {
      let linksSnapshot = snapshot.val()
      setLinks(linksSnapshot)
    })

    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  let classes = ''
  if (elementDimensions) {
    scrollSize = scrollPos
    classes = isActive(scrollPos, elementDimensions, limit, 100, 300, 'platforms')
  }


  return <div id='descargas' className={classes} ref={platformsRef}>
    <span className='title'>Vívelo ahora en</span>
    <div className='platform-container'>
      <div className='platform-item'>
        <img src={androidIcon} alt='android' />
        <div className='button'>
          <a href={links.AndroidURL} target='_blank' rel='noopener noreferrer'>Descargar</a>
        </div>
      </div>

      <div className='platform-item'>
        <img src={iosIcon} alt='ios' />
        <div className='button'>
          <a href={links.IOSURL} target='_blank' rel='noopener noreferrer'>Descargar</a>
        </div>
      </div>

      <div className='platform-item'>
        <img src={appleIcon} alt='apple store' />
        <div className='button'>
          <a href={links.MacOsURL} target='_blank' rel='noopener noreferrer'>Descargar</a>
        </div>
      </div>

      <div className='platform-item'>
        <img src={windowsIcon} alt='windows' />
        <div className='button'>
          <a href={links.WindowsURL} target='_blank' rel='noopener noreferrer'>Descargar</a>
        </div>
      </div>
    </div>
  </div>
}

export default Platforms