import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { isActive } from '../../utils/activeElements'
import Button from '../Button'
import './EventForm.css'


const isDev = process.env.NODE_ENV === 'development'
  , requestUrl = isDev
    ? 'https://hoppia.herokuapp.com/info-test'
    : 'https://hoppia.herokuapp.com/info'

let scrollSize = 0

const EventForm = props => {

  const contactRef = useRef(null)
    , { scrollPos, limit } = props
    , [elementDimensions, setElementDimensions] = useState(null)
    , onResize = () => {
      let dimensions = contactRef.current.getBoundingClientRect()
        , sizes = { top: dimensions.top, bottom: dimensions.bottom, height: dimensions.height }

      if (scrollSize > 0) {
        sizes.top = scrollSize + sizes.top
        sizes.bottom = scrollSize + sizes.bottom
      }
      setElementDimensions(sizes)
    }

  const [email, setEmail] = useState('')
    , [name, setName] = useState('')
    , [message, setMessage] = useState('')
    , [phone, setPhone] = useState('')
    , [isSending, setIsSending] = useState(false)
    , [error, setError] = useState('')
    , [isEmailRegistered, setIsEmailRegistered] = useState(false)

  const onSubmit = () => {
    setError('')
    //eslint-disable-next-line
    let emailPattern = /^(([^<>()\[\]\\.,:\s@"]+(\.[^<>()\[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!emailPattern.test(email)) return setError('Ingresa un correo electrónico valido <ejemplo@hoppia.world>')
    if (!name.trim().length) return setError('Ingresa tu nombre de contacto')

    setIsSending(true)

    axios({ method: 'POST', url: requestUrl, data: { email, name, message, phone } })
      .then(res => {
        setTimeout(() => {
          setIsSending(false)
          if (res.data.status === 200) setIsEmailRegistered(true)
          else setError('Ocurrió un error, por favor intentalo de nuevo ')
        }, 1000)
      })
      .catch(err => {
        setError('Ocurrió un error, por favor intentalo de nuevo ')
        setIsSending(false)
      })
  }

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])


  let classes = ''
  if (elementDimensions) {
    scrollSize = scrollPos
    classes = isActive(scrollPos, elementDimensions, limit, window.innerWidth < 768 ? 40 : 70, 180, 'alucina')
  }


  return <div id='organizar-evento' className={classes} ref={contactRef}>
    <div className='main-text'>
      <p>¿Quiéres</p>
      <p>organizar</p>
      <p>tu evento</p>
      <p>en Hoppia?</p>
    </div>
    <div className='form-container'>
      <div>
        <p>Llena el siguiente formulario y en breve <br className="hidden-1200" /> nos pondremos en contacto contigo</p>
      </div>

      {
        isEmailRegistered
          ? <h3 style={{ marginTop: '2vh' }}>Gracias, pronto recibirás más información.</h3>
          : <React.Fragment>
            <div className='form-inputs'>
              <input
                value={name}
                onChange={e => setName(e.target.value)}
                type='text'
                placeholder='Nombre' />
              <input
                type='email'
                placeholder='E-mail'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <input
                type='phone'
                placeholder='Teléfono'
                value={phone}
                onChange={e => setPhone(e.target.value)}
              />
              <textarea
                onChange={e => setMessage(e.target.value)}
                value={message}
                placeholder='Mensaje...'></textarea>
            </div>

            {
              isSending
                ? <span id='spin-loader' />
                : <Button isActive={true} onClick={onSubmit}>
                  <span>Enviar</span>
                </Button>
            }
          </React.Fragment>
      }



      <p className='error'>{error}</p>
    </div>
  </div>
}

export default EventForm