import React, { useEffect, useRef, useState } from 'react'
import { isActive } from '../../utils/activeElements'
import { fbDatabase } from '../../services/fb'

import Button from '../Button'
import EventCard from './components/EventCard'
import Slider from 'react-slick'

import './Events.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

let scrollSize = 0

const SlideBtn = ({ className, style, onClick }) => {
  return <div
    className={`slide-btn ${className}`}
    style={{ ...style, }}
    onClick={onClick}
  />
}


const SliderEvents = ({ events }) => {

  const settings = {
    infinite: events.length > 2,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SlideBtn />,
    prevArrow: <SlideBtn />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return <Slider {...settings}>
    {
      events.map((eventInfo, index) => <EventCard key={index} event={eventInfo} />)
    }
  </Slider>
}

const Events = props => {
  const [events, setEvents] = useState([])
    , [filteredEvents, setFilteredEvents] = useState([])
    , [showNewEvents, setShowNewEvents] = useState(true)
    , eventsRef = useRef(null)
    , { scrollPos, limit } = props
    , [elementDimensions, setElementDimensions] = useState(null)
    , onResize = () => {
      let dimensions = eventsRef.current.getBoundingClientRect()
        , sizes = { top: dimensions.top, bottom: dimensions.bottom, height: dimensions.height }

      if (scrollSize > 0) {
        sizes.top = scrollSize + sizes.top
        sizes.bottom = scrollSize + sizes.bottom
      }
      setElementDimensions(sizes)
    }
    , onClickHandler = active => {
      setShowNewEvents(active)
      onFilterEventHandler(events, active)
    }
    , onFilterEventHandler = (events, eventsFilter) => {
      /* let filter = eventsFilter === undefined ? showNewEvents : eventsFilter
        , dataNow = Date.now()
        , filterFunction = filter ? e => { return e.now > dataNow } : e => e.now < dataNow
        , eventList = events.map(evt => ({ ...evt, now: (new Date(evt.EndDate)).getTime() })).filter(filterFunction) */

      events = events
        .map(evt => ({ ...evt, now: (new Date(evt.EndDate)).getTime() }))
        .sort((eventA, eventB) => eventA.SortIndex - eventB.SortIndex)
      setFilteredEvents(events)
    }

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)

    const eventsRef = fbDatabase.ref('EVENTS').orderByChild('IsReady').equalTo(true)
    eventsRef.on('value', snapshot => {
      let eventSnapshot = snapshot.val()
        , eventList = []

      for (let id in eventSnapshot)
        eventList.push({ id, ...eventSnapshot[id] })

      onFilterEventHandler(eventList)
      setEvents(eventList)
    })

    return () => {
      window.removeEventListener('resize', onResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let classes = ''
  if (elementDimensions) {
    scrollSize = scrollPos
    classes = isActive(scrollPos, elementDimensions, limit, 40, 170, 'events')
  }

  return <div id='eventos' className={classes} ref={eventsRef}>
    <div className='buttons-container'>
      <Button isActive={showNewEvents} onClick={() => onClickHandler(true)}>
        <span>Eventos</span>
      </Button>
      {/*       <Button isActive={!showNewEvents} onClick={() => onClickHandler(false)}>
        <span>Eventos pasados</span>
      </Button> */}
    </div>
    <div className='slider-container'>
      <SliderEvents events={filteredEvents} />
    </div>
  </div>
}

export default Events

/*
 <div className='slider-container'>

    </div>
*/