import React, { useEffect, useRef, useState } from 'react'
import { isActive } from '../../utils/activeElements'

import f1Logo from '../../assets/images/f1logo.png'
import ocesaLogo from '../../assets/images/ocesalogo.png'
import redbullLogo from '../../assets/images/redbullogo.png'
import mattelLogo from '../../assets/images/matellogo.png'
import clementine from '../../assets/images/clementine.png'

import './Alucina.css'

let scrollSize = 0

const Alucina = props => {

  const alucinaRef = useRef(null)
    , { scrollPos, limit } = props
    , [elementDimensions, setElementDimensions] = useState(null)
    , onResize = () => {
      let dimensions = alucinaRef.current.getBoundingClientRect()
        , sizes = { top: dimensions.top, bottom: dimensions.bottom, height: dimensions.height }

      if (scrollSize > 0) {
        sizes.top = scrollSize + sizes.top
        sizes.bottom = scrollSize + sizes.bottom
      }
      setElementDimensions(sizes)
    }

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  let classes = ''
  if (elementDimensions) {
    scrollSize = scrollPos
    console.log()
    classes = isActive(scrollPos, elementDimensions, limit, window.innerWidth < 768 ? 40 : 70, 180, 'alucina')
  }


  return <div id='alucina' className={classes} ref={alucinaRef}>
    <div className='banner'>
      <svg viewBox="0 0 500 150" preserveAspectRatio="none" >
        <path d="M-55.86,-22.20 C77.88,49.83 455.98,218.58 500.56,54.77 L500.00,150.00 L0.00,150.00 Z" style={{ stroke: 'none', fill: 'whitesmoke' }}></path>
      </svg>
    </div>
    <div className='content'>
      <div className='text-container'>
        <p>Hoppia es un producto creado por expertos con mas de 12 años de experiencia en el desarrollo de plataformas digitales de entretenimiento y videojuegos.</p>
      </div>
      <div className='brands'>
        <p>Con productos para marcas como</p>
        <div className='brand-logos'>
          <img src={f1Logo} alt='f1' />
          <img src={ocesaLogo} alt='ocesa' />
          <img src={redbullLogo} alt='red bull' />
          <img src={mattelLogo} alt='mattel' />
        </div>
      </div>
    </div>
    <div id='clementine'>
      <img src={clementine} alt='clementine' />
    </div>
  </div>
}

export default Alucina

/*


*/