import React from 'react'

const EventCard = props => {
  let { event } = props
    , date = typeof event.StartDate === 'string'
      ? event.StartDate.split('T')[0]
      : ''

  return <div className='event-card'>
    <div className='event-poster'
      style={{ background: `url("${event.PosterURL}")` }}>
      <div className='shadow' />
    </div>
    <div>
      <p>{event.Title}</p>
      <span>{date}</span>
    </div>
  </div>
}

export default EventCard
