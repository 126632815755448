import React, { useState } from 'react'
import logo from '../../assets/images/logo_white.png'
import './Hero.css'

const Hero = props => {
  const [loadSection, setLoadSection] = useState('')
  const onChange = e => {
    let { currentTime } = e.target
    if (currentTime > 4 && currentTime < 27)
      setLoadSection('active')
    else
      setLoadSection('')
  }
    , onScroll = element => {
      let section = document.getElementById(element)
      if (section) section.scrollIntoView({ behavior: "smooth" })
    }

  return <div id='hero'>
    <video autoPlay muted loop onTimeUpdate={onChange}>
      <source src='https://hoppia.s3-us-west-2.amazonaws.com/website/website_loop_720.mp4' type='video/mp4' />
    </video>
    <div className={`content ${loadSection}`}>
      <div className='header'>
        <span onClick={() => onScroll('eventos')}>Eventos</span>
        <span onClick={() => onScroll('descargas')}>Descargar</span>
        <img src={logo} alt='hoppia-logo' />
        <span onClick={() => onScroll('organizar-evento')}>Organizar evento</span>
        <span onClick={() => onScroll('contacto')}>Contacto</span>
      </div>
      <div className="intro_text">
        <p>
          Tus <b>eventos virtuales</b> con <b>avatares</b>, <br className="hidden_movil" />
          <b>interacciones</b> en vivo, <b>juegos</b> y más
            </p>
      </div>
      <div style={{ height: '1vh' }} />
    </div>
  </div>
}

export default Hero

/*
import React from 'react'
import YoutubeBackground from 'react-youtube-background'
import backgroundVideo from '../../assets/videos/background.mp4'
import logo from '../../assets/images/logo_white.png'
import './Hero.css'

const Hero = props => {
  const onScroll = (id = 'hero') => {
    let element = document.getElementById(id)
    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    window.scrollTo(1000, 1000)
    console.log('D:')
  }

  return <div id='hero'>
    <YoutubeBackground videoId='hqCu6cqTuDQ' >
      <div className='content'>
        <div className='header'>
          <span onClick={() => onScroll('events')}>Eventos</span>
          <span>Descargar</span>
          <img src={logo} alt='hoppia-logo' />
          <span>Organizar evento</span>
          <span>Contacto</span>
        </div>
        <div>
          <p>
            Tus <b>eventos virtuales</b> con <b>avatares</b>,
          </p>
          <p>
            <b>interacciones</b> en vivo, <b>juegos</b> y más
          </p>
        </div>
        <div style={{ height: '1vh' }} />
      </div>
    </YoutubeBackground>
  </div>
}

export default Hero
 */